import { del, get, post, put, patch, uploadFile } from './api_setup'
import * as url from './url_helper'

// ** Authentification
const getUserAuthorization = email => get(url.GET_USER_AUTHORIZATION + `?email=${email}`)
const getUserAppsAccess = data => post(url.GET_USER_APPS_ACCESS, data)
const getAuthUser = (email, configs = {}) => get(url.GET_USER + email, configs)
const getAuthUserCustomerId = (email, configs = {}) => get(url.GET_USER_CUSTOMER_ID + email, configs)
// const getAdminCustomer = ({ email }, configs = {}) => get(url.GET_ADMIN_CUSTOMER + email, configs)
const getAdminCustomer = ({ domain }, configs = {}) => get(url.GET_ADMIN_CUSTOMER_BY_DOMAIN + domain, configs)
const getUserLicenceAccess = (email, configs = {}) => get(url.GET_USER_LICENCE_ACCESS + email, configs)

// ** Users
const getUsers = ({ id }) => get(url.GET_USERS + '?customerId=' + id)
const getUsersByQuery = (query, domain) => get(url.GET_USERS_BY_QUERY + query + '?domain=' + domain)

// ** Subscriptions
const getSubscriptions = ({ id, rows = 20 }) => get(url.GET_LIST_SUBSCRIPTIONS + id + `/${rows}`)
const getOrderHistory = ({ id }) => get(url.GET_ORDER_HISTORY + id)
const getLicenceAccess = ({ id, configs = {} }) => get(url.GET_LICENCES_ACCESS + id, configs)
const changeLicenceSeat = ({ licenceId, adminId }, data) => post(url.POST_CHANGE_SEAT + licenceId + '/' + adminId, data)
const createLicenceAccess = data => post(url.POST_CREATE_LICENCE_ACCESS, data)
const deleteLicenceAccess = userId => del(url.DELETE_LICENCE_ACCESS + userId)
const createAutoTopUp = payload => post(url.POST_AUTO_TOPUP, payload)
const updateAutoTopUp = (uid, payload) => put(url.PUT_AUTO_TOPUP + uid, payload)
const deleteAutoTopUp = uid => del(url.DELETE_AUTO_TOPUP + uid)
const getAutoTopupList = ({ customerId }) => get(url.GET_AUTO_TOPUP_LIST + customerId)
const getCustomerWhenPurchase = ({ customerId }) => get(url.GET_CUSTOMER_PURCHASE_LICENCE + customerId)
const createOrderHistory = orderHistory => post(url.POST_CREATE_ORDER_HISTORY, orderHistory)
const sendEmailPurchaseLicence = mail => post(url.POST_SEND_MAIL, mail)

// ** PURCHASE
const getCategories = () => get(url.GET_LIST_CATEGORIES)
const getAllCategorySkus = data => post(url.GET_ALL_SKUS, data)
const getCategorySkus = (data, domain) => post(url.GET_PRODUCT_SKU + domain, data)
const getEntitlments = domain => get(url.GET_ENTITLMENTS + domain)
const getListOffers = (data, domain) => post(url.GET_LIST_OFFERS + domain, data)
const purchaseSubscription = data => post(url.POST_PURCHASE_SUBSCRIPTION, data)

export {
  getAuthUser,
  getUserAuthorization,
  getUserAppsAccess,
  getUsers,
  getSubscriptions,
  getOrderHistory,
  getAdminCustomer,
  getLicenceAccess,
  changeLicenceSeat,
  createLicenceAccess,
  deleteLicenceAccess,
  createAutoTopUp,
  getAutoTopupList,
  updateAutoTopUp,
  deleteAutoTopUp,
  getCustomerWhenPurchase,
  sendEmailPurchaseLicence,
  createOrderHistory,
  getUsersByQuery,
  getCategories,
  getCategorySkus,
  getEntitlments,
  getAllCategorySkus,
  getListOffers,
  purchaseSubscription,
  getUserLicenceAccess,
  getAuthUserCustomerId
}
