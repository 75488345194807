// ** Icon imports
import LicencesIcon from 'mdi-material-ui/GoogleMyBusiness'
import UsersIcons from 'mdi-material-ui/AccountGroupOutline'
import ConfigsIcon from 'mdi-material-ui/Cog'
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined'

// ** Third Party Libraries
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { useAuth } from 'hooks/useAuth'

const navigation = () => {
  const auth = useAuth()

  // ** Initialize base constants
  const token = Cookies.get('token')
  const tokenDataDecoded = token ? jwt_decode(token) : null

  return [
    {
      title: 'Purchase Licenses',
      icon: LicencesIcon,
      path: '/licences'
    },
    ...(tokenDataDecoded?.ua
      ? [
          {
            title: 'Users',
            icon: UsersIcons,
            path: '/group-access'
          }
        ]
      : []),
    ...(!!auth.user?.customerInformation?.showAutoTopUp
      ? [
          {
            title: 'Configuration',
            icon: ConfigsIcon,
            path: '/configs'
          }
        ]
      : []),
    ...(auth.user?.supportCaseAccess
      ? [
          {
            title: 'Support Case',
            icon: SupportAgentOutlinedIcon,
            path: '/support-case',
            children: [
              {
                title: 'All cases',
                path: '/support-case/all'
              }
            ]
          }
        ]
      : [])
    // {
    //   title: 'Subscriptions',
    //   icon: SubscriptionsIcon,
    //   path: '/subscriptions'
    // }
  ]
}

export default navigation
