// Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Countries Imports
import { getAllCases, getCase } from 'configs/axios/admin-plus-apis/api_helper'

export const initialState = {
  loading: null,
  cases: [],
  case: {},
  success: '',
  error: '',
  nextPageToken: null
}
// A slice
const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getCasesSuccess: (state, action) => {
      state.cases = action.payload
      state.loading = null
      state.success = 'GET_CASES'
    },
    getCaseSuccess: (state, action) => {
      state.case = action.payload
      state.loading = null
      state.success = 'GET_CASE'
    },
    updateCaseSuccess: (state, action) => {
      state.user = { ...state.user, ...action.payload }
      state.loading = null
      state.success = 'UPDATE_CASE'
    },
    getCasesFailure: state => {
      state.loading = null
      state.error = 'ERROR'
      state.success = ''
    },
    setErrorMessage: (state, action) => {
      state.loading = null
      state.error = action.payload
      state.success = ''
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const { resetMessages, startLoading, getCaseSuccess, getCasesSuccess, updateCaseSuccess, getCasesFailure } =
  casesSlice.actions

// export user selector to get the slice in any component
export const casesSelector = state => state.casesData

// export The reducer
const casesReducer = casesSlice.reducer
export default casesReducer

// Actions
export const getCasesData = ({id, status, priority, zendeskId}) => async dispatch => {
  dispatch(startLoading('GET'))
  try {
    const response = await getAllCases({id, status, priority,zendeskId})
    dispatch(getCasesSuccess(response?.data?.[0] || []))
  } catch (error) {
    dispatch(getCasesFailure())
  }
}

export const getCaseData = id => async dispatch => {
  dispatch(startLoading('GET'))
  try {
    const response = await getCase(id)
    dispatch(getCaseSuccess(response?.data || {}))
  } catch (error) {
    dispatch(getCasesFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}

export const resetCaseData = () => dispatch => {
  dispatch(getCaseSuccess({}))
}
