// ** React Imports
import React, { useState, useEffect, Fragment, useMemo } from 'react'

// ** APIs
import {
    getSubscriptions,
    createAutoTopUp,
    getAutoTopupList,
    deleteAutoTopUp,
    updateAutoTopUp
} from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Switch from '@mui/material/Switch'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

// ** Component Imports
import CustomTable from '../components/tables/CustomTable'
import CustomAvatar from 'core/components/mui/avatar'

// ** Hooks
import useFetch from 'hooks/useFetch'
import { useAuth } from 'hooks/useAuth'

// ** Icon Imports

// ** Google Products Data
import { products } from '@fake-db/products'
import AlertMessage from '../components/alert/AlertMessage'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
    borderBottomWidth: 1,
    color: theme.palette.grey[300],
}))


const Row = props => {

    // ** Props
    const { row, setRowsPerPage, key, autoTopUpList, refetch, transferPayloadHandler } = props

    // ** State
    const [checked, setChecked] = useState(false)
    const [seatsToAdd, setSeatsToAdd] = useState(0)
    const [minSeatsDiff, setMinSeatsDiff] = useState(0)

    // ** Memo
    const setFields = useMemo(() => {
        const entity = autoTopUpList?.find(item => item.subscriptionId?.toString() === row.subscriptionId?.toString())
        if (entity?.uid) {
            setChecked(true)
            setSeatsToAdd(parseInt(entity.seatsToAdd))
            setMinSeatsDiff(parseInt(entity.diffNumberOfSeats))
        }
    }, [autoTopUpList])

    // ** Fucntions
    const productIcon = (name) => products.find(el => el.displayName === name)?.defaultLogo?.content

    const changeAutoToUpHandler = async (event) => {
        setChecked(event.target.checked)
        if (!event.target.checked) {
            setMinSeatsDiff(0)
            setSeatsToAdd(0)
        }
        const entity = autoTopUpList?.find(item => item.subscriptionId?.toString() === row.subscriptionId?.toString())
        const payload = {
            customerId: row.customerId,
            subscriptionId: row.subscriptionId,
            customerDomain: row.customerDomain,
            diffNumberOfSeats: minSeatsDiff,
            productName: row.skuName,
            seatsToAdd: seatsToAdd,
            checked: event.target.checked,
            mode: entity?.uid ? 'update' : 'create',
            uid: entity?.uid || null
        }
        transferPayloadHandler(payload)
    }

    const onChangeField = async (event) => {
        if (event.target.name === "diffNumberOfSeats") setMinSeatsDiff(event.target.value)
        else setSeatsToAdd(event.target.value)
        const entity = autoTopUpList?.find(item => item.subscriptionId?.toString() === row.subscriptionId?.toString())
        const payload = {
            customerId: row.customerId,
            subscriptionId: row.subscriptionId,
            customerDomain: row.customerDomain,
            diffNumberOfSeats: event.target.name === "diffNumberOfSeats" ? parseInt(event.target.value || 0) : minSeatsDiff,
            productName: row.skuName,
            seatsToAdd: event.target.name === "seatsToAdd" ? parseInt(event.target.value || 0) : seatsToAdd,
            checked: checked,
            mode: entity?.uid ? 'update' : 'create',
            uid: entity?.uid || null
        }
        transferPayloadHandler(payload)
    }



    return (
        <Fragment key={key}>
            <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
                <TableCell component="th" scope="row">
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <CustomAvatar src={productIcon(row.skuName) || ""} sx={{ mr: 3, width: '1.875rem', height: '1.875rem', borderRadius: 0 }} />
                        <Typography noWrap variant='body2' sx={{ color: 'common.blue' }}>{row.skuName}</Typography>
                    </Box>
                </TableCell>
                <TableCell>
                    <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        name="diffNumberOfSeats"
                        value={minSeatsDiff}
                        onChange={onChangeField}
                        InputProps={{ inputProps: { min: 1 } }}
                        sx={{ width: 80 }}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        name="seatsToAdd"
                        value={seatsToAdd}
                        onChange={onChangeField}
                        InputProps={{ inputProps: { min: 1 } }}
                        sx={{ width: 80 }}
                    />
                </TableCell>
                <TableCell>
                    <Switch checked={checked} onChange={changeAutoToUpHandler} />
                </TableCell>
            </TableRow>

        </Fragment>
    )
}



const LicencesTable = () => {

    // ** State
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [message, setMessage] = useState({ success: false, error: false, value: "" })
    const [autoTopUpArray, setAutoTopUpArray] = useState([]) // Holding the set of promises of autotopup to be executed
    const [spinner, setSpinner] = useState(false)

    // ** Hooks
    const auth = useAuth()
    const { data, loading, error, refetch: refetchSubs } = useFetch(getSubscriptions, { id: auth.user?.customerId, rows: rowsPerPage })
    const { data: autoTopUpList, refetch } = useFetch(getAutoTopupList, { customerId: auth.user?.customerId })

    // ** Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const saveAutopopupChanges = async () => {
        setSpinner(true)
        try {
            Promise.all(autoTopUpArray?.map(async (autoTopUp) => {
                if (autoTopUp?.mode === "create" && autoTopUp?.checked) {
                    try {
                        const { checked, mode, ...rest } = autoTopUp // remove testing variables
                        const response = await createAutoTopUp(rest)
                    } catch (error) {
                        console.log('error: ', error)
                    }
                } else if (autoTopUp?.mode === "update" && autoTopUp?.checked) {
                    try {
                        const { checked, mode, uid, ...rest } = autoTopUp // remove testing variables
                        const response = await updateAutoTopUp(uid, rest)
                    } catch (error) {
                        console.log("error: ", error)
                    }
                } else if (autoTopUp?.mode === "update" && !autoTopUp?.checked) {
                    try {
                        const { checked, mode, uid, ...rest } = autoTopUp // remove testing variables
                        const response = await deleteAutoTopUp(uid)
                    } catch (error) {
                        console.log("error: ", error)
                    }
                }
            })).then(res => {
                setMessage({ success: true, error: false, value: "Actions were done successfully" })
                setSpinner(false)
                refetch()
                refetchSubs()
            })
        } catch (error) {
            setSpinner(true)
            setMessage({ success: false, error: true, value: "There was an error, please try again." })
        }

    }

    const collectPayload = (payload) => {

        setAutoTopUpArray([...autoTopUpArray.filter(item => item?.subscriptionId !== payload?.subscriptionId), payload])
    }

    const handleCloseMessage = () => {
        setMessage({ success: false, error: false, value: "" })
    }

    return (
        <Card
            sx={{
                '&.MuiPaper-root': {
                    borderRadius: "0px !important",
                    boxShadow: 0,
                    border: theme => `1px solid ${theme.palette.grey[300]}`
                },
                '& .customer-column-header': {
                    backgroundColor: 'common.white',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600
                    }
                },
            }}>
            <CardHeader
                sx={{ mb: "-1rem" }}
                title={<Typography>Manage Licenses</Typography>}
                action={
                    <Button
                        disabled={spinner}
                        onClick={saveAutopopupChanges}
                        variant="contained"
                        endIcon={(spinner ?
                            <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null
                        )}
                    >
                        Save
                    </Button>
                }
            />
            <StyledDivider />
            <CustomTable
                data={data?.subscriptions ?? []}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                columns={["PRODUCT", "LICENSE CAP", "INCREASE BY", "AUTO TOP-UP"]}
                page={page}
                rowsPerPage={rowsPerPage}
                loading={loading}
            >
                {data?.subscriptions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                    <Row
                        key={index}
                        row={row}
                        setRowsPerPage={setRowsPerPage}
                        autoTopUpList={autoTopUpList?.[0] || []}
                        refetch={refetch}
                        transferPayloadHandler={collectPayload}
                    />
                ))}
            </CustomTable>
            <AlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Card>

    )
}

export default LicencesTable
