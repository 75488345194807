// ** React Imports
import React, { Fragment, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** APIs
import { updateCase } from 'configs/axios/admin-plus-apis/api_helper'

// ** MUI Imports
import {
  Button,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  CircularProgress
} from '@mui/material'

// ** Icons Imports

const CloseCaseDialog = props => {
  // ** Props
  const { caseData } = props

  // ** Hooks
  const navigate = useNavigate()

  // ** State
  const [open, setOpen] = useState(false)
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)

  // ** Ref
  const radioGroupRef = useRef(null)

  // ** Functions
  const handleClickListItem = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }
  const handleCancel = () => {
    setOpen(false)
    handleCloseDialog()
  }

  const onClickOutSide = (event, reason) => {
    if (reason && reason == 'backdropClick') return
    handleCloseDialog()
  }

  const submitClose = async () => {
    try {
      const data = {
        ...caseData,
        ...(caseData.productType ? { productType: caseData.productType } : { productType: '' }),
        ...(caseData.language ? { language: caseData.language } : { language: '' }),
        status: 'closed'
      }
      setLoading(true)
      const response = await updateCase(caseData?.uid, data)
      setLoading(false)
      navigate('/support-case/all')
    } catch (error) {
      setLoading(false)
      console.log('----error----', error)
    }
  }

  return (
    <>
      <Button
        onClick={handleClickListItem}
        variant='text'
        sx={{
          marginTop: '10px',
          fontWeight: 600,
          color: 'common.blue',
          fontSize: '0.825rem',
          whiteSpace: 'nowrap'
        }}
      >
        Close case
      </Button>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        onClose={onClickOutSide}
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start'
          }
        }}
      >
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign='start' mb={3}>
              <Typography variant='body1' fontSize={16} fontWeight={500}>
                Close case
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant='body1' fontSize={14} mb={3}>
                Are you sure you want to close this case ?
              </Typography>
              <TextField
                disabled={loading}
                placeholder='Add a comment'
                variant='outlined'
                fullWidth
                onChange={e => setComment(e.target.value)}
                multiline
                rows={3}
                InputProps={{
                  style: { borderRadius: 0 }
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'end' }}>
              <Button autoFocus onClick={handleCancel} color='inherit'>
                Cancel
              </Button>
              <Button
                disabled={loading}
                endIcon={loading ? <CircularProgress size={16} sx={{ color: '#fff' }} /> : null}
                onClick={submitClose}
                variant='contained'
                size='small'
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CloseCaseDialog
