import axios from "axios";
import Cookies from 'js-cookie'

//auth https://api-dot-adminplus-accelerant.oa.r.appspot.com
//apply base url for axios
const API_URL = "https://adminplus-accelerant.oa.r.appspot.com/api"
const axiosApi = axios.create({
  baseURL: API_URL,
});
axiosApi.defaults.headers.Accept = 'application/json'
axiosApi.defaults.headers.post['Content-Type'] = 'application/json';
axiosApi.interceptors.request.use(
  (config) => {
    const token = Cookies.get('ap_token')
    if (token) {
      config.headers["x-auth-token"] = token
    }

    return config
  },
  (err) => Promise.reject(err)
)



export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response).catch(error => error);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response);
}


export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}
