// ** React Imports
import React from 'react'
import { Navigate, redirect } from 'react-router-dom'

// ** Authentifications
import Login from 'pages/auth'

// Licences
import Licences from 'pages/licences'
// import Subscriptions from 'pages/subscriptions/containers/AvailableLicences'
// import PurchaseSku from 'pages/subscriptions/containers/PurchaseSku'
// import GoogleWorkspaceServices from 'pages/subscriptions/containers/GoogleWorkspaceServices'

// Users
import Users from 'pages/users'

//Configs
import LicencesTable from 'pages/configs/containers/Licences'

// Support case
import CasesTable from "pages/support-case/containers/CasesTable"
import AddCase from "pages/support-case/containers/AddCase"
import Case from "pages/support-case/containers/Case"

const authProtectedRoutes = [
  { path: '/licences', component: Licences },
  // { path: '/subscriptions', component: Subscriptions },
  { path: "/support-case/all", component: CasesTable },
  { path: "/support-case/add", component: AddCase },
  { path: "/support-case/case/:id", component: Case },
  // { path: '/subscriptions/purchase', component: PurchaseSku },
  // { path: '/subscriptions/google-workspace', component: GoogleWorkspaceServices },
  { path: '/group-access', component: Users },
  { path: '/configs', component: LicencesTable },
  { path: '/', exact: true, component: () => <Navigate to='/licences' /> },
  { path: '*', component: () => <Navigate to='/licences' /> }
]

const publicRoutes = [{ path: '/login', component: Login }]

export { authProtectedRoutes, publicRoutes }
