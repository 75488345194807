// ** React Imports
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { Button, Grid, Dialog, DialogContent, TextField, Typography, CircularProgress } from '@mui/material'

// ** APIs Imports
import { createCase } from 'configs/axios/admin-plus-apis/api_helper'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** moment
import moment from 'moment'

const extractDomainFromEmail = email => {
  const parts = email.split('@')
  if (parts.length === 2) {
    const [, domain] = parts
    return domain
  } else {
    return null
  }
}

const FollowUpTicketDialog = props => {
  // ** Props
  const { caseData } = props

  // ** Hooks
  const { user } = useAuth()
  const navigate = useNavigate()

  // ** State
  const [open, setOpen] = useState(false)
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)

  // ** Ref
  const radioGroupRef = useRef(null)

  // ** Functions
  const handleClickListItem = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }
  const handleCancel = () => {
    setOpen(false)
    handleCloseDialog()
  }

  const onClickOutSide = (event, reason) => {
    if (reason && reason == 'backdropClick') return
    handleCloseDialog()
  }

  const submitClose = async () => {
    try {
      const body = {
        subject: 'Follow up ticket for #' + caseData?.zendeskId,
        description: description,
        priority: caseData?.priority,
        userEmail: user.primaryEmail,
        username: user.name,
        date: moment().format('YYYY-MM-DD'),
        customerId: user.customerId,
        customerDomain: extractDomainFromEmail(user.primaryEmail),
        productType: caseData?.productType || 'google_workspace',
        language: caseData?.language || 'english',
        status: 'New',
        category: caseData?.category || '',
        viaFollowupSourceId: caseData?.zendeskId
      }
      setLoading(true)
      const response = await createCase(body)
      setLoading(false)
      navigate('/support-case/all')
    } catch (error) {
      setLoading(false)
      console.log('----error----', error)
    }
  }

  return (
    <>
      <Button
        onClick={handleClickListItem}
        variant='text'
        sx={{
          marginTop: '10px',
          fontWeight: 600,
          color: 'common.blue',
          fontSize: '0.825rem',
          whiteSpace: 'nowrap'
        }}
      >
        create a follow up ticket
      </Button>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        onClose={onClickOutSide}
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start'
          }
        }}
      >
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign='start' mb={3}>
              <Typography variant='body1' fontSize={16} fontWeight={500}>
                Create a follow up ticket to the case {caseData?.zendeskId}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant='body1' fontSize={14} mb={3}>
                Add a description (optional) ?
              </Typography>
              <TextField
                disabled={loading}
                placeholder='Add a comment'
                variant='outlined'
                fullWidth
                onChange={e => setDescription(e.target.value)}
                multiline
                rows={3}
                InputProps={{
                  style: { borderRadius: 0 }
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'end' }}>
              <Button autoFocus onClick={handleCancel} color='inherit'>
                Cancel
              </Button>
              <Button
                disabled={loading}
                endIcon={loading ? <CircularProgress size={16} sx={{ color: '#fff' }} /> : null}
                onClick={submitClose}
                variant='contained'
                size='small'
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default FollowUpTicketDialog
