// ** Authentification
export const GET_USER_AUTHORIZATION = '/direct'
export const GET_USER_APPS_ACCESS = '/auth/admins/appsaccess'
export const GET_ADMIN_CUSTOMER = '/licenseCustomers/getAdminCustomer/'
export const GET_ADMIN_CUSTOMER_BY_DOMAIN = '/licenseCustomers/getAdminByDomain/'
export const GET_USER_LICENCE_ACCESS = '/licenceAccess/get?email='

// ** Users
export const GET_USER_QUERY = '/users/get/query'
export const GET_USER = '/users/'
export const GET_USER_CUSTOMER_ID = '/users/get/userInfo/'
export const GET_USERS = '/users/all'
export const GET_USERS_BY_QUERY = '/users/getUsersByQuery/'

// ** Subscriptions
export const GET_LIST_SUBSCRIPTIONS = '/purchaseLicences/subscriptions/list/'
export const GET_ORDER_HISTORY = '/purchaseLicences/subscriptions/orderHistory/list/'
export const GET_LICENCES_ACCESS = '/licenceAccess/getLicenceAccess/'
export const POST_CHANGE_SEAT = '/purchaseLicences/subscriptions/changeSeats/'
export const POST_CREATE_LICENCE_ACCESS = '/licenceAccess/create/'
export const DELETE_LICENCE_ACCESS = '/licenceAccess/delete/'
export const POST_AUTO_TOPUP = '/autoTopUp/create'
export const PUT_AUTO_TOPUP = '/autoTopUp/update/'
export const DELETE_AUTO_TOPUP = '/autoTopUp/delete/'
export const GET_AUTO_TOPUP_LIST = '/autoTopUp/list/'
export const GET_CUSTOMER_PURCHASE_LICENCE = '/purchaseLicences/customers/get/'
export const POST_CREATE_ORDER_HISTORY = '/purchaseLicences/subscriptions/orderHistory/create'
export const POST_SEND_MAIL = '/mailsend'

// ** Purchase
export const GET_LIST_CATEGORIES = '/purchaseLicences/products/'
export const GET_PRODUCT_SKU = '/purchaseLicences/skus?domain='
export const GET_ENTITLMENTS = '/purchaseLicences/subscriptions/listEntitlments?domain='
export const GET_ALL_SKUS = '/purchaseLicences/listAllSkus'
export const GET_LIST_OFFERS = '/purchaseLicences/offers?domain='
export const POST_PURCHASE_SUBSCRIPTION = '/purchaseLicences/subscriptions/createSubscription/'
