// ** React Imports
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { casesSelector, getCaseData, resetCaseData } from 'store/supportCase'
import { getAllUsers } from 'configs/axios/admin-plus-apis/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import { Box } from '@mui/material'

// ** Icon Imports
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

// ** Third Party Libraries Imports
import { useForm } from 'react-hook-form'
import moment from 'moment'

// ** Custom Components
import ConversationTemplate from '../components/conversation/ConversationTemplate'
import FollowUpTicketDialog from '../components/dialog/FollowUpTicketDialog'
import CloseCaseDialog from '../components/dialog/CloseCaseDialog'
import FileUploader from '../components/Uploader'
import AutocompleteForm from '../components/form/AutocompleteForm'
import SelectForm from '../components/form/SelectForm'

// ** Data
import { timezone } from '@fake-db/timezone-gmt'

// ** Styled Grid Container
const Container = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginBottom: '2rem',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    paddingRight: 0
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}))

const Label = styled(Typography)(({ theme }) => ({
  fontFamily: 'Google Sans,Helvetica Neue,sans-serif !important',
  fontWeight: 450,
  fontSize: '18px',
  lineHeight: '24px'
}))

const SubLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto,Helvetica Neue,sans-serif !important',
  fontWeight: 450,
  fontSize: '14px',
  lineHeight: '20px'
}))

const priorities = [
  { label: 'P4 - Critical', value: 'urgent', background: '#fce8e6', color: '#c5221f' },
  { label: 'P3 - High', value: 'high', background: '#fef7e0', color: '#c5221f' },
  { label: 'P2 - Medium', value: 'normal', background: '#fef7e0', color: '#000' },
  { label: 'P1 - Low', value: 'low', background: '#f1f3f4', color: '#000' }
]

const Case = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const { id } = useParams()
  // ** Hooks
  const { control, reset, watch } = useForm({
    defaultValues: {},
    mode: 'onChange'
  })

  // ** Selectors
  const { case: caseData, success, error, loading } = useSelector(casesSelector)

  // ** State
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('Billing') // Définissez la valeur initiale ici
  const [options, setOptions] = useState([])

  // ** Fetch user data
  useEffect(() => {
    const fetchUsersData = async () => {
      const response = await getAllUsers()
      const {
        data: { result }
      } = response
      setOptions(result)
    }
    if (id) {
      dispatch(getCaseData(id))
      fetchUsersData()
    }
    return () => {
      dispatch(resetCaseData())
    }
  }, [id])

  useEffect(() => {
    if (!open) {
      setValue(value)
    }
  }, [value, open])

  const resetData = useMemo(() => {
    if (caseData?.uid) {
      reset({
        productType: caseData?.productType || '',
        category: caseData?.category || '',
        subject: caseData?.subject || '',
        description: caseData?.description || '',
        priority: caseData?.priority || '',
        language: caseData?.language || 'english',
        timezone: timezone.find(item => item.value === caseData.timezone) ?? '',
        caseSharing: options.filter(item => caseData?.caseSharing?.some(el => el == item.primaryEmail))
      })
    }
  }, [caseData, options])

  // ** Constants
  const { background, color, label } =
    priorities.find(item => item.value?.toLocaleLowerCase() === caseData?.priority?.toLowerCase()) || ''
  const files = caseData?.comments?.map(item => item.attachments)?.flat(Infinity)

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', gap: 4, py: 3, px: 5 }}>
        <Typography
          fontSize={14}
          fontWeight={500}
          color='primary.main'
          sx={{
            '&:hover': {
              color: theme => theme.palette.primary.main,
              cursor: 'pointer'
            }
          }}
        >
          Home
        </Typography>
        <Typography variant='subtitle1'>&gt;</Typography>
        <Typography variant='subtitle1' fontSize={14} fontWeight={500} color='primary.main'>
          All cases
        </Typography>
        <Typography variant='subtitle1'>&gt;</Typography>
        <Typography variant='subtitle1' fontSize={14} fontWeight={500}>
          Case : {caseData?.uid}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} sx={{ alignItems: 'center' }}>
        <Label variant='h5' sx={{ px: 5, pt: 3, fontWeight: 400 }}>
          {caseData?.subject}
        </Label>
      </Grid>
      <Grid item xs={12} sx={{ px: 5, py: 3 }}>
        <Label variant='body1' sx={{ fontSize: 14 }}>
          Created by <strong>{caseData?.username}</strong> {'<'}
          {caseData?.userEmail}
          {'>'} for {caseData?.customerDomain} on{' '}
          {caseData?.date ? moment(caseData?.date, 'YYYY-MM-DD').format('MMM DD, YYYY') : ''}
        </Label>
      </Grid>
      <Grid item xs={12} sx={{ mb: '2rem', mt: '1rem' }}>
        <Divider variant='fullWidth' sx={{ color: '#e8eaed' }} />
      </Grid>
      <Container container spacing={12}>
        <Grid item container xs={12} sx={{ position: 'relative', mx: '1rem' }}>
          <form id='primary-form' style={{ width: '100%' }}>
            <Grid container xs={12} lg={12} sx={{ mt: '0rem', maxWidth: '2%' }}>
              <Grid item xs={12} md={3} lg={2} sx={{ alignItems: 'center' }}>
                <Label>General information</Label>
              </Grid>
              <Grid xs={6} md={5} sx={{ display: 'flex', mt: '0rem', marginLeft: '25px' }}>
                <Grid item xs={12} md={12} sx={{}}>
                  <Typography variant='subtitle2' sx={{}}>
                    Product type
                  </Typography>
                  <Typography variant='h7' sx={{ mb: '1rem', display: 'block', fontWeight: 500 }}>
                    Google Workspace
                  </Typography>
                  <SelectForm
                    fullWidth
                    control={control}
                    name='priority'
                    label='Priority selection'
                    options={[
                      { label: 'P4 - Critical impact', value: 'critical' },
                      { label: 'P3 - High impact', value: 'high' },
                      { label: 'P2 - Medium impact', value: 'medium' },
                      { label: 'P1 - Low impact', value: 'low' }
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} sx={{ display: 'flex', gap: 3, px: 3 }}>
                <hr />
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center' }}
                >
                  <Typography
                    variant='subtitle2'
                    sx={{
                      fontSize: '12px',
                      p: '0 4px',
                      color: color,
                      cursor: 'pointer',
                      backgroundColor: background,
                      borderRadius: '5px'
                    }}
                  >
                    {label}
                  </Typography>

                  {caseData?.status?.toLowerCase() === 'closed' ? (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <HighlightOffIcon sx={{ marginRight: '10px' }} />
                        <Typography variant='h7' fontWeight={600} textTransform='capitalize'>
                          {caseData?.status}
                        </Typography>
                      </div>
                      <FollowUpTicketDialog caseData={caseData} />
                    </>
                  ) : caseData?.status === 'New' || caseData?.status === 'new' ? (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <NewReleasesOutlinedIcon sx={{ marginRight: '10px' }} />
                        <Typography variant='h7' fontWeight={600} textTransform='capitalize'>
                          {caseData?.status}
                        </Typography>
                      </div>
                      <CloseCaseDialog caseData={caseData} />
                    </>
                  ) : (
                    <Typography variant='h7' fontWeight={600} textTransform='capitalize'>
                      {caseData?.status}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider variant='fullWidth' sx={{ color: '#e8eaed', my: 10 }} />
            <Grid container xs={12} lg={12} sx={{ mt: '0rem', maxWidth: '2%' }}>
              <Grid item xs={12} md={3} lg={2} sx={{ alignItems: 'center' }}>
                <Label>Case overview</Label>
                <SubLabel>Describe the issue</SubLabel>
              </Grid>
              <Grid item xs={12} md={6} lg={6} display='flex' flexDirection='column' gap={5} px={5}>
                <TextField
                  label='Case title'
                  variant='outlined'
                  value={caseData?.subject ?? ''}
                  disabled
                  InputProps={{
                    style: { borderRadius: 0 }
                  }}
                />
                <Box display>
                  <Typography sx={{ fontSize: 12, color: 'text.secondary' }}>Category</Typography>
                  <Typography variant='body1'>Billing</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: 12, color: 'text.secondary' }}>Language</Typography>
                  <Typography variant='body1'>English</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: 12, color: 'text.secondary' }}>Case description</Typography>
                  <Typography variant='body1'>{caseData?.description}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: '2rem', mt: '3rem' }}>
              <Divider variant='fullWidth' sx={{ color: '#e8eaed' }} />
            </Grid>
            <Grid container xs={12} lg={12} mb={5}>
              <Grid item xs={12} md={3} lg={2}>
                <Label>Attachments</Label>
                <SubLabel>Add screenshots or log files</SubLabel>
              </Grid>
              <Grid xs={6} md={6} px={5}>
                <FileUploader filesUrls={files || []} />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: '2rem', mt: '3rem' }}>
              <Divider variant='fullWidth' sx={{ color: '#e8eaed' }} />
            </Grid>
            <Grid item container xs={12} lg={10} sx={{ mt: '3rem' }}>
              <Grid item xs={12} md={3}>
                <Label>Case sharing</Label>
                <SubLabel>Add email addresses to let others track this case</SubLabel>
              </Grid>
              <Grid item xs={12} md={9} container spacing={5}>
                <Grid item xs={12} md={8}>
                  <AutocompleteForm
                    multiple
                    disabled
                    control={control}
                    defaultValue={watch('caseSharing') ?? []}
                    fullWidth
                    name='caseSharing'
                    placeholder='email...'
                    label='Subscribers'
                    options={options}
                    getOptionLabel={option => `${option?.name?.fullName} (${option?.primaryEmail})` || ''}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SubLabel sx={{ borderLeft: '1px solid #e8eaed', px: '16px' }}>
                    When you share a case, you may reveal information to someone who isn't an Administrator. Email
                    addresses can be removed at any time.
                    <a href='https://support.google.com/a/answer/10759436#casesharing' target='_blank' rel='noreferrer'>
                      See guidelines <OpenInNewIcon sx={{ fontSize: 16 }} />
                    </a>
                  </SubLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: '2rem', mt: '3rem' }}>
              <Divider variant='fullWidth' sx={{ color: '#e8eaed' }} />
            </Grid>
            <Grid item container xs={12} lg={10} sx={{ mt: '3rem' }}>
              <Grid item xs={12} md={3}>
                <Label>Preferred time zone</Label>
                <SubLabel>Select your preferred time zone for contact.</SubLabel>
              </Grid>
              <Grid item xs={12} md={9}>
                <AutocompleteForm
                  control={control}
                  defaultValue={watch('timezone') ?? ''}
                  fullWidth
                  name='timezone'
                  placeholder='time zone...'
                  label='Time zone'
                  options={timezone || []}
                  getOptionLabel={option => option.text || ''}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: '2rem', mt: '3rem' }}>
              <Divider variant='fullWidth' sx={{ color: '#e8eaed' }} />
            </Grid>
            <ConversationTemplate caseData={caseData} />
          </form>
        </Grid>
      </Container>
    </Box>
  )
}

export default Case
