export const products = [
  {
    displayName: 'AppSheet Core (Additional Service)',
    defaultLogo: {
      content: 'https://www.gstatic.com/images/branding/product/1x/appsheet_64dp.png',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'AppSheet',
    defaultLogo: {
      content: 'https://www.gstatic.com/images/branding/product/1x/appsheet_64dp.png',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Cloud Identity Premium',
    defaultLogo: {
      content: 'https://www.gstatic.com/images/branding/product/1x/cloud_identity_64dp.png',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Cloud Identity',
    defaultLogo: {
      content: 'https://www.gstatic.com/images/branding/product/1x/cloud_identity_64dp.png',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Cloud Identity Free',
    defaultLogo: {
      content: 'https://www.gstatic.com/images/branding/product/1x/cloud_identity_64dp.png',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Business Plus',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Business Starter',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Frontline',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Business Standard',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Enterprise Essentials',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Enterprise Standard',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Enterprise Plus',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_superG_full_color_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Enterprise Standard - Archived User',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Archived User',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Business Plus - Archived User',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'G Suite Business - Archived User',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'G Suite Business',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'G Suite Basic',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Workspace Enterprise Plus - Archived User',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_apps_work_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Vault',
    defaultLogo: {
      content: 'https://www.google.com/images/icons/product/vault-64.png',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Drive storage',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/images/icons/product/drive-64.png',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Voice Standard',
    defaultLogo: {
      content:
        'https://fonts.gstatic.com/s/i/productlogos/voice_2020q4/v1/web-64dp/logo_voice_2020q4_color_1x_web_64dp.png',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Voice Premier',
    defaultLogo: {
      content:
        'https://fonts.gstatic.com/s/i/productlogos/voice_2020q4/v1/web-64dp/logo_voice_2020q4_color_1x_web_64dp.png',
      type: 'MEDIA_TYPE_IMAGE'
    }
  },
  {
    displayName: 'Google Meet Global Dialing',
    defaultLogo: {
      content: 'https://ssl.gstatic.com/apps/cpanel/resources/img/google_meet_logo_64.svg',
      type: 'MEDIA_TYPE_IMAGE'
    }
  }
]
