// ** React Imports
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// ** Redux Imports
import { useDispatch } from 'react-redux'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { Box, Button, CircularProgress, FormControl, IconButton } from '@mui/material'

// ** Third Party Libraries
import moment from 'moment'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

// ** Icon Imports
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// ** Custom Components
import SelectForm from '../components/form/SelectForm'
import TreeViewDialog from '../components/dialog/TreeViewDialog'
import FormInput from '../components/form/InputForm'
import CustomAlertMessage from '../components/alert/CustomAlertMessage'
import FileUploader from '../components/Uploader'
import AutocompleteForm from '../components/form/AutocompleteForm'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** APIs
import { createCase, getAllUsers } from 'configs/axios/admin-plus-apis/api_helper'

// ** Data
import { timezone } from '@fake-db/timezone-gmt'

// ** Styled Grid Container
const Container = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginBottom: '2rem',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    paddingRight: 0
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}))

const Label = styled(Typography)(({ theme }) => ({
  fontFamily: 'Google Sans,Helvetica Neue,sans-serif !important',
  fontWeight: 450,
  fontSize: '18px',
  lineHeight: '24px'
}))

const SubLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto,Helvetica Neue,sans-serif !important',
  fontWeight: 450,
  fontSize: '14px',
  lineHeight: '20px'
}))

const List = styled('ul')(({ theme }) => ({}))

const Item = styled('li')(({ theme }) => ({
  fontFamily: 'Roboto,Helvetica Neue,sans-serif !important',
  fontSize: '14px',
  lineHeight: '20px'
}))

// ** The browser timezone
const browserTimezone = new Date().getTimezoneOffset()

const defaultValues = {
  productType: 'google_workspace',
  category: 'technical',
  subject: '',
  description: '',
  priority: 'low',
  date: moment().format('YYYY-MM-DD'),
  status: 'New',
  language: 'english',
  timezone: timezone.find(item => item.utc.includes(Intl.DateTimeFormat().resolvedOptions().timeZone))
}

// Schema validation
const schema = yup.object().shape({
  subject: yup.string().min(1, 'Subject should be a clear sentence').required('Subject is required'),
  description: yup.string().min(1, 'Description should contain at least 10 characters').required('Subject is required')
})

const AddCases = () => {
  // ** Hooks
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { user } = useAuth()

  // ** State
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, value: null })
  const [options, setOptions] = useState([])

  // ** Fetch users on mount
  useEffect(() => {
    const fetchUsersData = async () => {
      const response = await getAllUsers()
      const {
        data: { result }
      } = response
      setOptions(result)
    }
    fetchUsersData()
  }, [])

  // ** Functions
  const extractDomainFromEmail = email => {
    const parts = email.split('@')
    if (parts.length === 2) {
      const [, domain] = parts
      return domain
    } else {
      return null
    }
  }

  const handleCloseMessage = () => setMessage({ success: false, error: false, value: '' })

  const onSubmit = async data => {
    setLoading(true)
    const body = {
      subject: data.subject,
      description: data.description,
      priority: data.priority,
      userEmail: user.primaryEmail,
      username: user.name.fullName,
      date: data.date,
      customerId: user.customerId,
      customerDomain: extractDomainFromEmail(user.primaryEmail),
      productType: data.productType,
      language: data.language,
      category: data.category,
      status: 'New',
      viaFollowupSourceId: '',
      timezone: data.timezone.value,
      ...(data?.caseSharing?.length > 0 ? { caseSharing: data?.caseSharing?.map(item => item.primaryEmail) } : {}),
      attachments: data.attachments.map(item => item.token)
    }
    try {
      const response = await createCase(body)
      setMessage({ success: true, error: false, value: 'New Case submitted successfully.' })
      setLoading(false)
      setTimeout(() => navigate('/support-case/all'), 1500)
    } catch (error) {
      setLoading(false)
      setMessage({
        success: false,
        error: true,
        value: 'There was an error while submitting the case, please try again.'
      })
    }
  }

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 3, px: 5 }}>
        <Typography
          variant='subtitle1'
          sx={{
            color: 'black',
            '&:hover': {
              color: theme => theme.palette.primary.main,
              cursor: 'pointer'
            }
          }}
          onClick={() => navigate('/support-case/all')}
        >
          All cases
        </Typography>
        <Typography variant='subtitle1'>&gt;</Typography>
        <Typography variant='subtitle1' color='primary.main'>
          Create support case
        </Typography>
      </Grid>
      <Divider variant='fullWidth' sx={{ color: '#e8eaed' }} />
      <Grid container alignItems='center' sx={{ m: '1%' }}>
        <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant='h5'
            align='center'
            sx={{
              marginLeft: '2%',
              marginTop: '2%',
              color: 'black',
              fontFamily: 'Google Sans,Helvetica Neue,sans-serif !important',
              fontWeight: 400
            }}
          >
            Help and support
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mb: 8 }}>
        <Box
          sx={{
            mx: 5,
            px: 5,
            py: 1,
            backgroundColor: '#e8f0fe',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '11px'
          }}
        >
          <Box display='flex' alignItems='center' gap={1}>
            <IconButton>
              <InfoOutlinedIcon sx={{ color: 'primary.main' }} />
            </IconButton>
            <Typography variant='subtitle2'>Make sure the content below doesn't contain sensitive data.</Typography>
          </Box>
        </Box>
      </Grid>
      <Container container spacing={12}>
        <Grid item container xs={12} sx={{ position: 'relative', mx: '1rem' }}>
          <Grid container item xs={12}>
            <form id='support-case-form' style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
              <Grid item container xs={12} lg={10}>
                <Grid item xs={12} md={3}>
                  <Label>Select your product :</Label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <SelectForm
                    fullWidth
                    control={control}
                    errors={errors}
                    name='productType'
                    label='Product Type'
                    options={[
                      { label: 'Google Workspace', value: 'google_workspace' },
                      { label: 'Google Cloud Platform', value: 'gcp' },
                      { label: 'Other', value: 'other' }
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={10} sx={{ mt: '3rem' }}>
                <Grid item xs={12} md={3}>
                  <Label>How would you describe your issue?</Label>
                </Grid>
                <Grid container spacing={3} item xs={12} md={9}>
                  <Grid item xs={12}>
                    <FormInput
                      control={control}
                      errors={errors}
                      fullWidth
                      name='subject'
                      placeholder='issue...'
                      label='Describe your issue'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TreeViewDialog setValue={setValue} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} lg={10} sx={{ mt: '3rem' }}>
                <Grid item xs={12} md={3}>
                  <Label>Priority:</Label>
                </Grid>
                <Grid item xs={12} md={5.2}>
                  <SelectForm
                    fullWidth
                    control={control}
                    errors={errors}
                    name='priority'
                    label='Priority selection'
                    options={[
                      { label: 'P4 - Critical impact', value: 'critical' },
                      { label: 'P3 - High impact', value: 'high' },
                      { label: 'P2 - Medium impact', value: 'medium' },
                      { label: 'P1 - Low impact', value: 'low' }
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={10} sx={{ mt: '3rem' }}>
                <Grid item xs={12} md={3}>
                  <Label>Language selection :</Label>
                </Grid>
                <Grid item xs={12} md={9} container spacing={5}>
                  <Grid item xs={12} md={7}>
                    <SelectForm
                      fullWidth
                      control={control}
                      errors={errors}
                      name='language'
                      label='Language selection'
                      options={[{ label: 'English', value: 'english' }]}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <SubLabel sx={{ borderLeft: '1px solid #e8eaed', px: '16px', height: '60px' }}>
                      Language selection may affect your available support options.
                    </SubLabel>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} lg={10} sx={{ mt: '3rem' }}>
                <Grid item xs={12} md={3}>
                  <Label>Provide more details:</Label>
                  <SubLabel>Potential information to share</SubLabel>
                  <List>
                    {['Affected users', 'Devices impacted', 'Troubleshooting steps taken'].map((item, index) => (
                      <Item key={index}>{item}</Item>
                    ))}
                  </List>
                  <SubLabel>If possible, attach screenshots or log files</SubLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <FormInput
                    control={control}
                    errors={errors}
                    fullWidth
                    name='description'
                    placeholder='more details...'
                    label='Provide details'
                    multiline
                    rows={8}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} lg={10} sx={{ mt: '3rem' }}>
                <Grid item xs={12} md={3}>
                  <Label>Attachments</Label>
                  <SubLabel>Add screenshots or log files</SubLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth variant='outlined'>
                    <FileUploader setValue={setValue} />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container xs={12} lg={10} sx={{ mt: '3rem' }}>
                <Grid item xs={12} md={3}>
                  <Label>Case sharing</Label>
                  <SubLabel>Add email addresses to let others track this case</SubLabel>
                </Grid>
                <Grid item xs={12} md={9} container spacing={5}>
                  <Grid item xs={12} md={8}>
                    <AutocompleteForm
                      freeSolo
                      multiple
                      control={control}
                      errors={errors}
                      fullWidth
                      name='caseSharing'
                      placeholder='email...'
                      label='Subscribers'
                      options={options}
                      getOptionLabel={option =>
                        typeof option === 'string' ? option : `${option?.name?.fullName} (${option?.primaryEmail})`
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SubLabel sx={{ borderLeft: '1px solid #e8eaed', px: '16px' }}>
                      When you share a case, you may reveal information to someone who isn't an Administrator. Email
                      addresses can be removed at any time.
                    </SubLabel>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={10} sx={{ mt: '3rem' }}>
                <Grid item xs={12} md={3}>
                  <Label>Preferred time zone</Label>
                  <SubLabel>Select your preferred time zone for contact.</SubLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <AutocompleteForm
                    control={control}
                    errors={errors}
                    fullWidth
                    name='timezone'
                    placeholder='time zone...'
                    label='Time zone'
                    options={timezone || []}
                    getOptionLabel={option => option.text || ''}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={10} textAlign='start' sx={{ my: 10 }}>
            <Button
              type='submit'
              form='support-case-form'
              variant='contained'
              disabled={loading}
              endIcon={loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
              sx={{
                mx: 1,
                borderRadius: '5px !important',
                fontWeight: 600,
                color: 'common.white',
                fontSize: '0.825rem'
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
      <CustomAlertMessage message={message} handleCloseMessage={handleCloseMessage} />
    </Box>
  )
}

export default AddCases
