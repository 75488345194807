// ** React Imports
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// ** Third party libraries
import { useCookies } from 'react-cookie'
import axios from 'axios'

// ** Api Calls
import {
  getUserAppsAccess,
  getUserAuthorization,
  getAuthUser,
  getAdminCustomer,
  getUserLicenceAccess,
  getLicenceAccess
} from 'configs/axios/api_helper'
import { getAdminPlusAuthorization } from 'configs/axios/admin-plus-apis/api_helper'

// ** MUI Imports
import Box from '@mui/material/Box'

// ** SPINNER
import GoogleSpinner from 'core/components/google-spinner'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  error: false,
  isInitialized: false,
  login: () => null,
  logout: () => null,
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [error, setError] = useState(defaultProvider.error)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)

  // ** Hooks
  const navigate = useNavigate()

  // ** Cookies
  const [cookies, setCookie, removeCookie] = useCookies(['gToken', 'userData', 'externalToken', 'token'])

  // ** Get Token from external app redirection
  const search = window.location.search
  const params = new URLSearchParams(search)
  const externalToken = params.get('token')
  const externalGoogleToken = params.get('gToken')
  const externalUser = params.get('user') ? JSON.parse(params.get('user')) : null

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true)
      setLoading(true)
      const storedGoogleToken = cookies.gToken
      const token = cookies.token
      const storedUser = cookies.userData
      let adminData = null
      try {
        const googleUser = await axios
          .get(
            `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${
              externalGoogleToken ? externalGoogleToken : storedGoogleToken
            }`
          )
          .then(res => res.data)
        const [, domain] = googleUser?.email.split('@')
        const adminResponse = await getAdminCustomer(
          { domain: externalUser ? externalUser?.domain : domain },
          {
            headers: { 'x-auth-token': `${externalToken ? externalToken : token}` }
          }
        )
        if (adminResponse?.data?.[0]?.[0]) adminData = adminResponse?.data?.[0]?.[0]
        const authUser = await getAuthUser(externalUser ? externalUser?.admin : googleUser.email, {
          headers: { 'x-auth-token': `${externalToken ? externalToken : token}` }
        })
          .then(res => res.data?.result)
          .catch(error => {})
        //
        const [[customerInfo]] = await getUserLicenceAccess(externalUser ? externalUser.admin : googleUser.email, {
          headers: { 'x-auth-token': `${externalToken ? externalToken : token}` }
        })
          .then(res => res.data)
          .catch(error => {})

        // Stored User
        const supportCaseAccess = await fetch(
          'https://adminplus-accelerant.oa.r.appspot.com/api/access?email=' + storedUser?.email
        )
        setUser({
          ...authUser,
          customerId: !!authUser?.customerId ? authUser?.customerId : adminData?.customerId,
          email: externalUser ? externalUser?.admin : storedUser?.email,
          primaryEmail: authUser?.primaryEmail || storedUser?.email,
          picture: externalUser ? '' : storedUser?.picture,
          domain: externalUser?.domain ? externalUser?.domain : storedUser?.domain,
          access_apps: [],
          admin: adminData,
          customerInformation: externalUser?.domain
            ? { showAutoTopUp: externalUser?.showAutoTopUp }
            : !!customerInfo
            ? customerInfo
            : { showAutoTopUp: adminData?.showAutoTopUp },
          externalAdmin: externalGoogleToken ? googleUser?.email : null,
          supportCaseAccess: supportCaseAccess.status === 200 ? true : false
        })
        if (externalToken) setCookie('token', externalToken)
        setLoading(false)
        if (window.location.pathname == '/login') navigate('/')
        else navigate(window.location.pathname)
      } catch (error) {
        removeCookie('gToken')
        removeCookie('token')
        removeCookie('userData')
        setUser(null)
        setLoading(false)
        navigate('/login')
      }
    }
    initAuth()
  }, [])

  const handleLogin = async (data, errorCallback) => {
    setLoading(true)
    setError(false)
    let adminData = null
    try {
      // call apis for collecting variant user data & access
      const token = await getUserAuthorization(data.email).then(res => res.data?.token)
      const { token: ap_token } = await getAdminPlusAuthorization(data.email)
        .then(res => res.data)
        .catch(error => null)
      const userDataAuthResponse = await getAuthUser(data.email, { headers: { 'x-auth-token': `${token}` } })
        .then(res => res.data?.result)
        .catch(error => {})
      const adminResponse = await getAdminCustomer({ domain: data.hd }, { headers: { 'x-auth-token': `${token}` } })
      if (adminResponse?.data?.[0]?.[0]) adminData = adminResponse?.data?.[0]?.[0]

      // const customerData = getCustomerWhenPurchase({ customerId: userDataAuthResponse?.customerId })
      const [[customerInfo]] = await getUserLicenceAccess(data.email, { headers: { 'x-auth-token': `${token}` } })
        .then(res => res.data)
        .catch(error => {})
      // check support case access
      const supportCaseAccess = await fetch(
        'https://adminplus-accelerant.oa.r.appspot.com/api/access?email=' + data.email
      )
      // Initialise userData
      const userData = {
        ...userDataAuthResponse,
        ...(!userDataAuthResponse?.name ? { name: { fullName: data.name }, primaryEmail: data.email } : {}),
        customerId: !!userDataAuthResponse?.customerId ? userDataAuthResponse?.customerId : adminData?.customerId,
        email: data.email,
        picture: data.picture,
        domain: data.hd,
        access_apps: [],
        admin: adminData,
        customerInformation: !!customerInfo ? customerInfo : { showAutoTopUp: adminData?.showAutoTopUp },
        supportCaseAccess: supportCaseAccess.status === 200 ? true : false
      }
      // Set Cookies
      setCookie('gToken', data.access_token)
      setCookie('token', token)
      setCookie('ap_token', ap_token)
      setCookie('userData', userData)
      // Set auth data
      setUser(userData)
      setLoading(false)
      navigate('/')
    } catch (error) {
      setLoading(false)
      errorCallback({ message: 'not authorized', code: 401 })
      setError(true)
    }
  }

  const handleLogout = () => {
    setUser(null)
    setIsInitialized(false)
    removeCookie('userData')
    removeCookie('gToken')
    removeCookie('token')
    navigate('/login')
  }

  const values = {
    user,
    loading,
    error,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout
  }
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          mt: '20%'
        }}
      >
        <GoogleSpinner />
      </Box>
    )
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
