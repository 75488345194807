// ** React Imports
import React from 'react'

// ** MUI Imports
import { Grid } from '@mui/material'

// ** Component Imports
import OrderHistoryTable from './containers/OrderHistory'
import AvailableLicencesTable from './containers/AvailableLicences'

const Licences = () => {
  return (
    <Grid container spacing={10}>
      <Grid item xs={12}>
        <AvailableLicencesTable />
      </Grid>
      <Grid item xs={12}>
        <OrderHistoryTable />
      </Grid>
    </Grid>
  )
}

export default Licences
