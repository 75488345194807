// ** React Imports
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

// ** Custom Component
import DeleteUser from '../dialog/DeleteUser'


const Row = props => {

    // ** Hooks 
    const navigate = useNavigate()

    // ** Props
    const { row, refetch } = props


    return (
        <Fragment key={row.id}>
            <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
                <TableCell component='th' scope='row' sx={{ color: 'common.blue' }}>
                    {row.userName}
                </TableCell>
                <TableCell>{row.userEmail}</TableCell>
                <TableCell>{row.adminDetails?.name || "-----"}</TableCell>
                <TableCell><DeleteUser user={row} refetch={refetch}/></TableCell>
            </TableRow>
        </Fragment>
    )
}


export default Row