// ** React Imports
import React, { useState, Fragment, useMemo } from 'react'

// ** APIs
import { getOrderHistory, getSubscriptions } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

// ** Third Party Libraries
import Cookies from 'js-cookie'
import moment from 'moment'
import jwt_decode from 'jwt-decode'

// ** Component Imports
import CustomTable from '../components/tables/CustomTable'
import CustomAvatar from 'core/components/mui/avatar'

// ** Hooks
import useFetch from 'hooks/useFetch'
import { useAuth } from 'hooks/useAuth'

// ** Icon Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import CheckIcon from 'mdi-material-ui/CheckCircleOutline'

// ** Google Products Data
import { products } from '@fake-db/products'
import ConfirmAddLicence from '../components/dialog/ConfirmAddLicence'

// ** Constants
import { emailTemplate } from '@fake-db/mailTemplate'
import { useQuery } from '@tanstack/react-query'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

// ** Initialize base constants
const token = Cookies.get('token')
const tokenDataDecoded = token ? jwt_decode(token) : null

const Row = props => {
  // ** Props
  const { row, setRowsPerPage, key, refetch, refetchOrderHistory } = props

  // ** Hooks
  const auth = useAuth()

  // ** State
  const [numberOfLicences, setNumberOfLicences] = useState('')

  // ** Constants
  const mail = {
    from: 'orders@premiercloud.com', // sender address
    subject: `${row.skuName} License Purchase | ${auth.user?.admin?.domain || auth.user?.domain || ''}`, // Subject line
    bcc: 'ar@premiercloud.com, sales@premiercloud.com',
    html: emailTemplate(auth.user?.primaryEmail, numberOfLicences, row.skuName)
  }

  if (auth.user?.primaryEmail !== tokenDataDecoded?.adminId) {
    mail.to = auth.user?.primaryEmail
    mail.cc = auth.user?.admin?.admin
  } else {
    mail.to = auth.user?.primaryEmail
  }

  // ** Fucntions
  const productIcon = name =>
    products.find(el =>
      name?.toLocaleLowerCase()?.includes('workspace')
        ? el.displayName?.toLocaleLowerCase().includes('workspace')
        : el.displayName === name
    )?.defaultLogo?.content ??
    products.find(el => el.displayName?.toLocaleLowerCase().includes('workspace'))?.defaultLogo?.content

  const changeLicenceNumberHandler = event => {
    setNumberOfLicences(event.target.value)
  }

  const { data: orderHistories } = useQuery({
    queryKey: ['order_history'],
    queryFn: () => {
      return getOrderHistory({ id: auth.user?.customerId })
    }
  })
  const userDomain = auth.user?.primaryEmail?.split('@')?.[1]
  const orderHistoryObject = {
    customerId: `${auth.user?.customerId}`,
    orderId: `${auth.user?.customerId}-${
      orderHistories?.data?.length < 10 ? '0' + orderHistories?.data?.length + 1 : orderHistories?.data?.length + 1
    }`,
    customerDomain: userDomain ? userDomain : auth.user?.admin?.domain,
    timestamp: new Date().getTime(),
    productName: row.skuName,
    productLogo: productIcon(row.skuName) || '',
    customerDetail: {
      name: auth?.user?.externalAdmin ? auth?.user?.externalAdmin : auth.user?.name?.fullName,
      email: auth?.user?.externalAdmin ? auth?.user?.externalAdmin : auth.user?.primaryEmail
    },
    seats: numberOfLicences
  }

  return (
    <Fragment key={key}>
      <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
        <TableCell component='th' scope='row'>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CustomAvatar
              src={productIcon(row.skuName) || ''}
              sx={{ mr: 3, width: '1.875rem', height: '1.875rem', borderRadius: 0 }}
            />
            <Typography noWrap variant='body2' sx={{ color: 'common.blue' }}>
              {row.skuName}
            </Typography>
          </Box>
        </TableCell>
        <TableCell width='fit-content' sx={{ whiteSpace: 'nowrap' }}>
          {row?.plan?.planName !== 'FREE' && row.seats ? (
            <Box display='flex' alignItems='center' gap={1}>
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: 'GrayText' }}>
                {row?.plan?.planName === 'FLEXIBLE'
                  ? row.seats?.maximumNumberOfSeats + ' available, '
                  : row.seats?.numberOfSeats - row.seats?.licensedNumberOfSeats + ' available, '}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'GrayText'
                }}
              >
                {(row.seats?.licensedNumberOfSeats || 0) + ' assigned'}
              </Typography>
            </Box>
          ) : (
            '----'
          )}
        </TableCell>
        <TableCell>
          {row.plan?.commitmentInterval?.endTime
            ? moment(new Date(parseInt(row.plan?.commitmentInterval?.endTime))).format('MMM DD, YYYY')
            : '----'}
        </TableCell>
        {row.plan?.planName === 'FREE' ||
        (row.seats?.maximumNumberOfSeats >= 50000 && row?.plan?.planName === 'FLEXIBLE') ||
        row.available >= 50000 ? (
          <TableCell></TableCell>
        ) : (
          <TableCell>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TextField
                variant='outlined'
                size='small'
                type='number'
                value={numberOfLicences}
                onChange={changeLicenceNumberHandler}
                InputProps={{ inputProps: { min: 1 } }}
                sx={{ width: 80 }}
              />
              <ConfirmAddLicence
                setRowsPerPage={setRowsPerPage}
                numberOfLicences={numberOfLicences}
                currentMaxNumberOfSeats={
                  (row?.plan?.planName === 'FLEXIBLE' ? row?.seats?.maximumNumberOfSeats : row?.seats?.numberOfSeats) ||
                  0
                }
                licenceId={row.subscriptionId}
                planName={row.plan?.planName}
                refetch={refetch}
                mail={mail}
                orderHistoryObject={orderHistoryObject}
                setNumberOfLicences={setNumberOfLicences}
                skuName={row.skuName}
              />
            </Box>
          </TableCell>
        )}
      </TableRow>
    </Fragment>
  )
}

const AvailableLicencesTable = props => {
  // ** State
  const [collapsed, setCollapsed] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  // ** Hooks
  const auth = useAuth()

  // ** Queries
  const {
    data,
    isLoading: loading,
    refetch
  } = useQuery({
    queryKey: ['available_licences'],
    queryFn: () => {
      return getSubscriptions({ id: auth.user?.customerId, rows: rowsPerPage })
    }
  })

  const subscriptions = useMemo(() => {
    return data?.data?.subscriptions || []
  }, [data])

  // ** Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Card
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '0px !important',
          boxShadow: 0,
          border: theme => `1px solid ${theme.palette.grey[300]}`
        },
        '& .customer-column-header': {
          backgroundColor: 'common.white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600
          }
        }
      }}
    >
      <CardHeader
        sx={{ mb: '-1rem' }}
        title={<Typography>Available Licenses</Typography>}
        action={
          <IconButton
            size='small'
            sx={{ color: 'text.secondary', ...(collapsed && { mt: '-1rem' }) }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
          </IconButton>
        }
      />
      <StyledDivider />
      <Collapse in={collapsed} open={collapsed}>
        <CustomTable
          data={
            subscriptions?.sort((a, b) => {
              // Get the maxSeats values or default to -Infinity if the property is undefined
              const maxSeatsA =
                a.seats?.licensedNumberOfSeats !== undefined ? a.seats?.licensedNumberOfSeats : -Infinity
              const maxSeatsB =
                b.seats?.licensedNumberOfSeats !== undefined ? b.seats?.licensedNumberOfSeats : -Infinity

              // Compare the maxSeats values
              return maxSeatsB - maxSeatsA
            }) ?? []
          }
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          columns={['PRODUCT', 'STATUS', 'RENEWAL DATE', 'ADD LICENSES']}
          page={page}
          rowsPerPage={rowsPerPage}
          loading={loading}
        >
          {subscriptions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
            <Row key={index} row={row} setRowsPerPage={setRowsPerPage} refetch={refetch} />
          ))}
        </CustomTable>
      </Collapse>
    </Card>
  )
}

export default AvailableLicencesTable
