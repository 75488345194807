// ** React Imports
import React, { useEffect, useState } from "react"

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

// ** Custom Components
import GeneralInfo from "./components/profile-header/GeneralInfo"
import UsersTable from "./containers/UsersTable"




// ** Styled Grid Container
const Container = styled(Grid)(({ theme }) => ({
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginBottom: "2rem",
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

}))


const Users = (props) => {

    // ** State
    const [activeTab, setActiveTab] = useState(0)
    const [refetchSignal, setRefetechSignal] = useState(false)

    // ** Functions
    const changeTabsHandler = (tab) => setActiveTab(tab)

    return (
        <Container container spacing={6}>
            <Grid item xs={12} md={4} lg={4} xl={3}>
                <GeneralInfo
                    activeTab={activeTab}
                    changeTabsHandler={changeTabsHandler}
                    setRefetechSignal={setRefetechSignal}
                />
            </Grid>
            <Grid item xs={12} md={8} lg={8} xl={9}>
                <UsersTable
                    refetchSignal={refetchSignal}
                    setRefetechSignal={setRefetechSignal}
                />
            </Grid>
        </Container >
    )
}


export default Users