// ** React Imports
import React, { Fragment, useEffect, useRef, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { FormControl, Button, CircularProgress } from '@mui/material'

// ** Icon Imports
import Conversation from './Conversation'

import { updateCase } from 'configs/axios/admin-plus-apis/api_helper'
import CustomAlertMessage from '../alert/CustomAlertMessage'

const ConversationPriorities = [
  { label: 'Show newest first', value: 'newest' },
  { label: 'Show oldest first', value: 'oldest' }
]

const Label = styled(Typography)(({ theme }) => ({
  fontFamily: 'Google Sans,Helvetica Neue,sans-serif !important',
  fontWeight: 450,
  fontSize: '18px',
  lineHeight: '24px'
}))

const SubLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto,Helvetica Neue,sans-serif !important',
  fontWeight: 450,
  fontSize: '14px',
  lineHeight: '20px'
}))

const ConversationTemplate = props => {
  // ** Props
  const { caseData } = props

  // ** State
  const [body, setBody] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, value: '' })
  const [caseComments, setCaseComments] = useState([])
  const [selectedValue, setSelectedValue] = useState('oldest')

  // ** Reinitialize caseData comments
  useEffect(() => {
    if (caseData?.comments?.length > 0) {
      setCaseComments([...caseData?.comments])
    }
  }, [caseData])

  // ** Functions

  const handleCloseMessage = () => setMessage({ success: false, error: false, value: '' })

  const onChangeBodyHandler = event => {
    setBody(event.target.value)
  }

  const handleSelectChange = event => {
    setSelectedValue(event.target.value)
  }

  const onSubmit = async event => {
    event.stopPropagation()
    setLoading(true)
    const data = {
      ...caseData,
      comments: [
        ...caseData.comments,
        {
          author_id: caseData.comments[0].author_id,
          body
        }
      ]
    }
    try {
      const comment = {
        author_id: caseData.comments[0].author_id,
        body,
        via: {
          channel: 'api',
          source: {
            from: {},
            to: {},
            rel: null
          }
        }
      }

      const response = await updateCase(caseData?.uid, data)
      setCaseComments(state => [...state, comment])
      setBody('')
      setMessage({ success: true, error: false, value: 'successfully.' })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setMessage({
        success: false,
        error: true,
        value: 'There was an error while submitting the case, please try again.'
      })
    }
  }

  return (
    <>
      <Grid container xs={12} lg={12} mb={5}>
        <Grid item xs={12} md={3} lg={2}>
          <Label>Case history</Label>
          <SubLabel>Reply to the support team</SubLabel>
        </Grid>
        <Grid xs={6} md={9} px={5}>
          <TextField
            fullWidth
            multiline
            value={body}
            onChange={onChangeBodyHandler}
            rows={10}
            label='Send a message to the support team'
            variant='outlined'
            InputProps={{
              style: { borderRadius: 0 }
            }}
          />
          <Grid item xs={12} md={10} textAlign='start' sx={{ my: 8 }}>
            <Button
              variant='contained'
              disabled={loading}
              onClick={onSubmit}
              endIcon={loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
              sx={{
                mx: 1,
                borderRadius: '5px !important',
                fontWeight: 600,
                color: 'common.white',
                fontSize: '0.825rem'
              }}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container item xs={12} sx={{ justifyContent: 'flex-end' }}>
          <Grid item xs={12} md={3}>
            <FormControl>
              <Select
                id='demo-simple-select-outlined'
                labelId='demo-simple-select-outlined-label'
                style={{ width: '200px' }}
                value={selectedValue}
                onChange={handleSelectChange}
              >
                {ConversationPriorities.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {caseComments?.map(comment => (
          <Conversation key={comment.id} comment={comment} />
        ))}
      </Grid>
      <Grid item xs={12} sx={{ mb: '10rem', mt: '3rem' }}></Grid>
      <CustomAlertMessage message={message} handleCloseMessage={handleCloseMessage} />
    </>
  )
}

export default ConversationTemplate
