// ** React Imports
import React, { Fragment, useRef, useState } from 'react'

// ** MUI Imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Box, FormControl, IconButton, InputAdornment } from '@mui/material'
import moment from 'moment'

// ** Icon Imports
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import Avatar from '@mui/material/Avatar';


const Conversation = props => {


  return (
    <>
      <Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: "20px" }}>

        <Grid item xs={12} md={2}></Grid>



        <Grid item xs={12} md={5}>



          <div style={{ display: 'flex' }}>
            {props.comment?.via.channel == "api" ?
              <>
                <AccountCircleIcon sx={{ marginRight: '10px', color: '#1a73e8', fontSize: '2.5rem' }} />
                <Typography variant='h7' sx={{ display: 'inline', }}>
                  <strong> You</strong>     {'<'}
                  {props.comment?.author_id} {'>'}
                </Typography>
              </>
              :
              <>
                <Avatar sx={{ marginRight: '10px', backgroundColor: '#dadce0', width: '35px', height: '35px' }}>
                  <SupportAgentRoundedIcon sx={{ fontSize: '30px' }} />
                </Avatar>
                <Typography
                  variant='subtitle1'
                  sx={{
                    backgroundColor: "#34a853",
                    color: "white",
                    padding: "0px 12px",
                    borderRadius: "10px",
                    lineHeight: "2",
                  }}
                >
                  Api (Premier Cloud Support)
                </Typography>

              </>
            }

          </div>








          {/* <Typography
            variant='subtitle2'
            sx={{ mt: '-1rem', marginLeft: '50px', fontWeight: 600, color: 'black' }}
          >
            Comment
          </Typography> */}
          {/* <TextField
              id='outlined-basic'
              defaultValue='pdf...'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton style={{ color: 'red' }}>
                      <PictureAsPdfRoundedIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            /> */}


          <p></p>
          <Typography variant='subtitle1' sx={{ fontSize: 17 }} >
            {props.comment.body}
          </Typography>

        </Grid>

        <Grid item xs={12} md={4} sx={{}}>
          <Typography variant='inherit' sx={{ fontSize: 16, marginLeft: "40%" }} >
            {props.comment?.created_at ? moment(props.comment?.created_at).format('MMM/DD/YYYY HH:mm:ss') : ''}
          </Typography>

        </Grid>




        <Grid item xs={12} md={9} sx={{ mb: '15px', mt: '10px', marginLeft: "20%" }}>
          <Divider sx={{ color: '#e0e0e0', fontWeight: 'bold' }} />
        </Grid>


      </Grid>



    </>
  )
}

export default Conversation
