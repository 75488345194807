// ** React Imports
import React, { useState, useMemo, Fragment } from 'react'

// ** APIs
import { getOrderHistory } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'

// ** moment
import moment from 'moment'

// ** Component Imports
import CustomTable from '../components/tables/CustomTable'
import CustomAvatar from 'core/components/mui/avatar'

// ** Hooks
import useFetch from 'hooks/useFetch'
import { useAuth } from 'hooks/useAuth'

// ** Icon Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import CheckIcon from 'mdi-material-ui/CheckCircleOutline'

// ** Google Products Data
import { products } from '@fake-db/products'
import { useQuery } from '@tanstack/react-query'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

const Row = props => {
  // ** Props
  const { row, key } = props

  // **Fucntions
  const productIcon = name =>
    products?.find(el => el.displayName?.toLocaleLowerCase()?.includes(name?.toLocaleLowerCase()))?.defaultLogo?.content

  return (
    <Fragment key={key}>
      <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
        <TableCell>{row.orderID}</TableCell>
        <TableCell>
          {row.timestamp ? moment(new Date(row.timestamp)).format('MMM DD, YYYY, HH:mm:ss') : '----'}
        </TableCell>
        <TableCell component='th' scope='row'>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CustomAvatar
              src={row.product ? productIcon(row.product) : productIcon('workspace')}
              sx={{ mr: 3, width: '1.875rem', height: '1.875rem', borderRadius: 0 }}
            />
            <Typography noWrap variant='body2' sx={{ color: 'common.blue' }}>
              {row.product}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{row.customerDetail?.customerName || '----'}</TableCell>
        <TableCell>{row.noOfSeats}</TableCell>
      </TableRow>
    </Fragment>
  )
}

const OrderHistoryTable = props => {
  // ** Hooks
  const auth = useAuth()

  // ** State
  const [collapsed, setCollapsed] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  // ** Queries
  const { data, isLoading: loading } = useQuery({
    queryKey: ['order_history'],
    queryFn: () => {
      return getOrderHistory({ id: auth.user?.customerId })
    }
  })

  const orderHistoryData = useMemo(() => {
    return data?.data || []
  }, [data])

  // ** Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Card
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '0px !important',
          boxShadow: 0,
          border: theme => `1px solid ${theme.palette.grey[300]}`
        },
        '& .customer-column-header': {
          backgroundColor: 'common.white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600
          }
        }
      }}
    >
      <CardHeader
        sx={{ mb: '-1rem' }}
        title={<Typography>Order History</Typography>}
        action={
          <IconButton
            size='small'
            sx={{ color: 'text.secondary', ...(collapsed && { mt: '-1rem' }) }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
          </IconButton>
        }
      />
      <StyledDivider />
      <Collapse in={collapsed} open={collapsed}>
        <CustomTable
          data={orderHistoryData ?? []}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          columns={['ORDER ID', 'TIMESTAMPS', 'PRODUCT', 'ORDERD BY', 'SEATS NUMBER']}
          page={page}
          rowsPerPage={rowsPerPage}
          loading={loading}
        >
          {orderHistoryData
            ?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row, index) => (
              <Row key={index} row={row} />
            ))}
        </CustomTable>
      </Collapse>
    </Card>
  )
}

export default OrderHistoryTable
