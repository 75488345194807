
// Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** Countries Imports
import {
    getUsers,
} from 'configs/axios/api_helper';


export const initialState = {
    loading: null,
    users: [],
    user: {},
    success: "",
    error: "",
    nextPageToken: null
};
// A slice
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        startLoading: (state, action) => {
            state.loading = action.payload
            state.error = ""
            state.success = ""
        },
        ssSuccess: (state, action) => {
            const { users, nextPageToken } = action.payload
            state.users = users
            state.loading = null
            state.nextPageToken = nextPageToken
            state.success = 'GET_USERS'
        },
        getUserSuccess: (state, action) => {
            state.user = action.payload
            state.loading = null
            state.success = 'GET_USER'
        },
        updateUserSuccess: (state, action) => {
            state.user = { ...state.user, ...action.payload }
            state.loading = null
            state.success = 'UPDATE_USER'
        },
        updateUserRoleSuccess: (state, action) => {
            state.user = { ...state.user, isAdmin: action.payload }
            state.loading = null
            state.success = 'UPDATE_USER'
        },
        getUsersFailure: (state) => {
            state.loading = null
            state.error = "ERROR"
            state.success = ""
        },
        setErrorMessage: (state, action) => {
            state.loading = null
            state.error = action.payload
            state.success = ""
        },
        resetMessages: (state) => {
            state.success = ""
            state.error = ""
            state.loading = null
        }
    },
});

// Actions generated from the slice
const {
    resetMessages,
    startLoading,
    getUsersSuccess,
    getUserSuccess,
    getUsersFailure,
    updateUserSuccess,
    updateUserRoleSuccess
} = usersSlice.actions;

// export user selector to get the slice in any component
export const usersSelector = (state) => state.usersData;


// export The reducer
const usersReducer = usersSlice.reducer;
export default usersReducer;



// Actions
export const fetchUsers = ({ limit, page, query } = {}) => async (dispatch) => {
    dispatch(startLoading("GET"));
    try {
        let response = {}
        const { data: { result } } = response
        dispatch(getUsersSuccess(result))
    } catch (error) {
        dispatch(getUsersFailure())
    }
}

export const fetchUserData = (id) => async (dispatch) => {
    dispatch(startLoading("GET"));
    try {
        const response = await getUsers(id)
        const { data: { result } } = response
        dispatch(getUserSuccess(result))
    } catch (error) {
        dispatch(getUsersFailure())
    }
}

export const editUser = (id, data) => async (dispatch) => {
    try {
        dispatch(startLoading("UPDATE_USER"));
        dispatch(updateUserSuccess(data))
    } catch (error) {
        dispatch(getUsersFailure())
    }
}

export const editUserRole = (id, data) => async (dispatch) => {
    try {
        dispatch(startLoading("UPDATE_USER"));
        dispatch(updateUserRoleSuccess(data.adminStatus))
    } catch (error) {
        dispatch(getUsersFailure())
    }
}


export const cleanMessages = () => (dispatch) => {
    dispatch(resetMessages())
}
