// ** React Imports
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'

// **  Custom Components
import GoogleSpinner from 'core/components/google-spinner'

const CustomTable = props => {
  // ** Props
  const { loading, data, columns, handleChangeRowsPerPage, handleChangePage, page, rowsPerPage, path, children } = props

  return (
    <Box sx={{ position: 'relative' }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((el, index) => (
                <TableCell key={index} sx={{ whiteSpace: 'nowrap' }}>
                  {el}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Backdrop
        open={loading}
        sx={{
          position: 'absolute',
          color: theme => theme.palette.common.blue,
          zIndex: theme => theme.zIndex.mobileStepper - 1,
          '&.MuiBackdrop-root': {
            backgroundColor: 'common.white'
          }
        }}
      >
        <GoogleSpinner />
      </Backdrop>
    </Box>
  )
}

export default CustomTable
