// ** React Imports
import React, { useEffect, useMemo, useState } from 'react'

// ** APIs
import { getUsersByQuery, createLicenceAccess } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

// ** Hooks
import { useAuth } from 'hooks/useAuth'
import useFetch from 'hooks/useFetch'

// ** Styled Tab List
const Container = styled(Box)(({ theme }) => ({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  paddingTop: '1rem',
  marginBottom: '2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  gap: 5,
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(5)
  }
}))

const GeneralInfo = props => {
  // ** Props
  const { setRefetechSignal } = props

  // ** Hooks
  const auth = useAuth()

  // ** State
  const [selectedUser, setSelectedUser] = useState(null)
  const [message, setMessage] = useState({ error: false, success: false, value: '', loading: false })
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [inputError, setInputError] = useState(false)

  // ** Functions
  const onChangeHandler = user => setSelectedUser(user)

  const filterHandler = async (event, email) => {
    const { value } = event.target
    setSelectedUser(value)
    if (value) {
      try {
        setLoading(true)
        const {
          data: { result }
        } = await getUsersByQuery(value, auth.user?.admin?.domain)
        setUsers(Array.isArray(result) ? result : [])
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('error: ', error)
      }
    } else {
      setUsers([])
    }
  }

  const addUserHandler = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the selected user's email matches the email format
    if (typeof selectedUser !== 'object' && !emailRegex.test(selectedUser)) {
      setInputError(true) // Set error state to true if not in email format
      return // Stop further execution of addUserHandler
    } else {
      setInputError(false) // Clear error state if in email format
    }

    setMessage({ ...message, loading: true })
    const data = {
      adminDetails: { id: auth.user?.id, name: auth.user?.name?.fullName ?? auth.user?.name },
      adminEmail: auth.user?.primaryEmail,
      customerId: auth.user?.customerId,
      userEmail: typeof selectedUser !== 'object' ? selectedUser : selectedUser?.primaryEmail,
      userName:
        typeof selectedUser !== 'object'
          ? selectedUser?.split('@')?.[0]
          : selectedUser?.name?.fullName ?? selectedUser?.name
    }
    try {
      const response = await createLicenceAccess(data)
      setRefetechSignal(true)
      setMessage({ error: true, success: false, loading: false, value: 'User was added successfully.' })
    } catch (error) {
      setMessage({
        error: true,
        success: false,
        loading: false,
        value: 'There was an error occured. Please try again.'
      })
    }
  }

  return (
    <Card
      sx={{
        borderRadius: 0.5,
        py: '0.5rem',
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        boxShadow: 0
      }}
    >
      <Container>
        <Typography sx={{ fontSize: '1.725rem' }}>User Access</Typography>
        <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
        <Autocomplete
          id='autocomplete-users'
          autoHighlight
          freeSolo
          onInputChange={filterHandler}
          popupIcon={''}
          loading={loading}
          getOptionLabel={option => option?.primaryEmail}
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              placeholder="Search for user's email"
              error={inputError} // Apply error state to the input
              helperText={inputError ? 'Please enter a valid email address' : ''} // Display error message
            />
          )}
          options={users}
          onChange={(event, value) => onChangeHandler(value)}
          sx={{ width: '100%' }}
        />
        <Button
          disabled={!selectedUser}
          onClick={addUserHandler}
          variant='contained'
          sx={{ mt: '2rem', borderRadius: 0 }}
          endIcon={message.loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
        >
          Add user
        </Button>
      </Container>
    </Card>
  )
}

export default GeneralInfo
