import { get, post, put } from './api_setup'
import * as url from './url_helper'

// ** Authentification
const getAdminPlusAuthorization = email => get(url.GET_USER_AUTHORIZATION + `?email=${email}`)
//const getAdminSupportCaseAccess = (email, configs = {}) => get(url.GET_ADMIN_SUPPORTCASE_ACCESS + email, configs)

const getAllUsers = () => get('/users/all')

// ** Support cases
const getAllCases = ({ id, status = '', priority = '', zendeskId = '' }) =>
  get(
    url.GET_ALL_CASES +
      id +
      `?${status ? 'status=' + status : ''}${priority ? '&priority=' + priority : ''}${
        zendeskId ? '&zendeskId=' + zendeskId : ''
      }`
  )
const createCase = data => post(url.CREATE_NEW_CASE, data)
const getCase = id => get(url.GET_CASE + id)
const updateCase = (id, data) => put(url.UPDATE_CASE + id, data)

export {
  getAdminPlusAuthorization,
  getAllCases,
  createCase,
  getCase,
  updateCase,
  getAllUsers
}
