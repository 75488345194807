import { useEffect, useState } from "react"


export default function useFetch(apiCall, params) {

    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    // ** Functions
    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await apiCall(params)
            setData(response.data)
        } catch (err) {
            console.log("errr", err)
            setError(err.response)
        } finally {
            setLoading(false)
        }
    }

    // ** Execute refetch when needed
    const refetch = () => {
        fetchData()
    }

    // ** On mount fetch data
    useEffect(() => { fetchData() }, [apiCall])

    return { data, error, loading, refetch }

}