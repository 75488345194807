// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import UsersReducer from "./users";
import CasesReducer from './supportCase'

// import { persistReducer, persistStore } from 'redux-persist';
// import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
}

// const persistedReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
    reducer: {
        usersData: UsersReducer,
        casesData: CasesReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: true
        })
})

// export const persistor = persistStore(store)

export default store
