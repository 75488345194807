// ** React Imports
import React, { useState, useEffect, useMemo } from 'react'

// **  APIs 
import { getLicenceAccess } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'

// ** Component Imports
import CustomTable from '../components/tables/CustomTable'

// ** Icon Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'

// **  Hooks
import { useAuth } from 'hooks/useAuth'
import useFetch from 'hooks/useFetch'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
    borderBottomWidth: 1,
    color: theme.palette.grey[300],
}))


const UsersTable = (props) => {

    // ** Props
    const { setRefetechSignal, refetchSignal } = props // refetch users list every time refetch signal is not null

    // ** State
    const [collapsed, setCollapsed] = useState(true)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    // ** Hooks
    const auth = useAuth()
    const { data, loading, error, refetch } = useFetch(getLicenceAccess, { id: auth.user?.customerId })

    // ** Memo
    const users = useMemo(() => { return data?.length > 0 ? data[0] : [] }, [data])

    // ** Functions
    const executeRefetch = useMemo(() => { if (refetchSignal) { refetch(); setRefetechSignal(false) } }, [refetchSignal])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <Card
            sx={{
                '&.MuiPaper-root': {
                    borderRadius: "0px !important",
                    boxShadow: 0,
                    border: theme => `1px solid ${theme.palette.grey[300]}`
                },
                '& .customer-column-header': {
                    backgroundColor: 'common.white',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600
                    }
                },
            }}>
            <CardHeader
                sx={{ mb: "-1rem" }}
                title={<Typography>Users</Typography>}
                action={
                    <IconButton
                        size='small'
                        sx={{ color: 'text.secondary', ...(collapsed && { mt: "-1rem" }) }}
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
                    </IconButton>
                } />
            <StyledDivider />
            <Collapse in={collapsed} open={collapsed}>
                <CustomTable
                    data={users ?? []}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangePage={handleChangePage}
                    columns={["USERNAME", "EMAIL", "CREATED BY", "ACTIONS"]}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    loading={loading}
                    refetch={refetch}
                />
            </Collapse>
        </Card>


    )
}

export default UsersTable
