// ** React Imports
import React, { Fragment, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** API CALLS
import { changeLicenceSeat, sendEmailPurchaseLicence, createOrderHistory } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'

// ** Hooks
import { useAuth } from 'hooks/useAuth'
import { useQueryClient } from '@tanstack/react-query'

// ** Custom Components
import Alert from '../alert'

const ConfirmAddLicence = props => {
  // ** Props
  const {
    setRowsPerPage,
    numberOfLicences,
    currentMaxNumberOfSeats,
    licenceId,
    planName,
    refetch,
    mail,
    orderHistoryObject,
    setNumberOfLicences,
    skuName
  } = props

  // ** Hooks
  const auth = useAuth()
  const queryClient = useQueryClient()
  // ** Selectors
  const { user } = useSelector(usersSelector)

  // ** State
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ error: false, success: false, value: '' })
  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const handleCloseMessage = () => {
    setMessage({ success: false, error: false, value: '' })
  }

  const onSubmit = async () => {
    setLoading(true)
    const data = {
      addLicenceValue: parseInt(currentMaxNumberOfSeats) + parseInt(numberOfLicences),
      planName: planName
    }
    try {
      const response = await changeLicenceSeat({ licenceId: licenceId, adminId: auth.user?.customerId }, data)
      const responseCreate = await createOrderHistory(orderHistoryObject)
      setRowsPerPage(10)
      setMessage({
        success: true,
        error: false,
        value: numberOfLicences < 2 ? 'Licence was added successfully' : 'Licences were added successfully'
      })
      refetch()
      queryClient.invalidateQueries({ queryKey: ['order_history'] })
      setLoading(false)
      setNumberOfLicences('')
      setOpen(false)
      const mailResponse = await sendEmailPurchaseLicence(mail)
    } catch (error) {
      setMessage({
        success: false,
        error: true,
        value: error?.response?.data?.errors[0]?.message ?? 'There was an error while adding the licences'
      })
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Button
        disableElevation
        disabled={numberOfLicences < 1 || !numberOfLicences}
        sx={{
          color: theme => `${numberOfLicences ? theme.palette.common.blue : theme.palette.text.secondary} !important`,
          fontWeight: 600,
          fontSize: '0.825rem'
        }}
        onClick={handleClickOpen}
      >
        Add Licenses
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>Add new licenses</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to add{' '}
            <strong>
              {numberOfLicences} {skuName}
            </strong>{' '}
            {numberOfLicences < 2 ? 'license' : 'licenses'} ?
          </Typography>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button disabled={loading} onClick={handleClose} size='small' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            endIcon={loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
            onClick={onSubmit}
            variant='contained'
            size='small'
            sx={{ fontWeight: 600, primary: 'common.blue' }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Alert message={message} handleCloseMessage={handleCloseMessage} />
    </Fragment>
  )
}

export default ConfirmAddLicence
